<template>
  <div>
    <div class="title">{{ title }}</div>
    <ul>
      <li v-for="(item, idx) in dataList" :key="idx" class="team-info">
        <div class="base-info">
          <span class="leader-info">
            <img :src="item.wxUser.avatar" class="avatar" />
            <div>{{ item.wxUser.nickname }}</div>
          </span>
          <span v-if="item.progressPercent<100">
            <van-button type="danger" size="mini" @click="handleShareTeam(item)">邀请好友加入</van-button>
            <van-button type="primary" size="mini" @click="toJoinTeam(item)">加入此队</van-button>
          </span>
          <span v-else>
            <van-tag type="warning">组队成功</van-tag>
          </span>
        </div>
        <div class="progress-info">
          <span class="progress-bar">
            <van-progress :percentage="item.progressPercent" stroke-width="6" color="#f2826a" />
          </span>
          <span class="progress-text">{{ item.personCnt }}/{{ item.personLimit }}</span>
        </div>

      </li>
    </ul>
    <van-goods-action>
      <van-goods-action-button type="primary" text="活动详情" @click="toDetail" />
      <van-goods-action-button type="danger" text="我要组队" @click="toCreateTeam" />
    </van-goods-action>
  </div>
</template>
<script>
import freeTeamApi from '@/api/free-team'
export default {
  data() {
    return {
      appId: '',
      activity: {
        id: ''
      },
      dataList: [],
      title: '团队列表'
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.activity.id = this.$route.params.id
  },
  mounted() {
    this.initDataList()
  },
  methods: {
    // 分享给好友
    handleShareTeam(data) {},
    initDataList() {
      freeTeamApi.getTeamList(this.activity.id).then(res => {
        if (res.succ) {
          this.dataList = res.data.items
        }
      })
    },
    toJoinTeam(team) {
      this.$router.push(`/${this.appId}/freeTeam/join/${this.activity.id}/${team.id}`)
    },
    toDetail() {
      this.$router.push(`/${this.appId}/freeTeam/detail/${this.activity.id}/-1`)
    },
    toCreateTeam() {
      this.$router.push(`/${this.appId}/freeTeam/create/${this.activity.id}/-1`)
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  background-color: #fff;
  font-size: 0.4rem;
  text-align: center;
  height: 0.6rem;
  line-height: 0.6rem;
  margin: 0 0 0.1rem 0;
  padding: 0.2rem;
}
.team-info {
  // border-bottom: 1px solid #ccc;
  padding: 0.2rem;
  background-color: #fff;
  margin-bottom: 0.1rem;
  .base-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.2rem;
    height: 1.2rem;
    .leader-info {
      display: flex;
      align-items: center;
      img.avatar {
        border: 1px solid #999;
        border-radius: 50%;
        width: 1.2rem;
        height: 1.2rem;
        display: inline-block;
      }
      div {
        display: inline-block;
        margin-left: 0.2rem;
        font-size: 0.2rem;
      }
    }
  }
  .progress-info {
    display: flex;
    line-height: 30px;
    height: 30px;
    align-items: center;
    justify-content: space-around;
    .progress-bar {
      flex: 5;
    }
    .progress-text {
      flex: 1;
      text-align: center;
      color: #999;
      font-size: 0.3rem;
      font-weight: bold;
      padding: 0.1rem;
    }
  }
}
</style>
